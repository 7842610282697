import React from 'react';
import {FormattedMessage} from 'react-intl';

import './button.scss';

const Button = () => {
  return (
    <a href="https://fightforua.org/" className="button" target="_blank" rel="noreferrer noopener">
      <FormattedMessage id="landing.buttonText" />
    </a>
  );
};

export default Button;
