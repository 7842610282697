import React from 'react';
import Wrapper from './wrapper';
import Button from './button';
import flag from '../images/icn_flag.svg';
import {FormattedMessage} from 'react-intl';

import './footer.scss';

const Footer = () => {
  return (
    <div className="bg-dark">
      <Wrapper>
        <div className="footer">
          <img
            className="flag-image"
            src={flag}
            width="75"
            height="75"
            alt="ukranian flag"
          />
          <h3 className="footer__title">
            <FormattedMessage id="landing.footerTitle" />
          </h3>
          <p className="footer__subtitle">
            <span className="footer__thin-text">
              <FormattedMessage id="landing.footerSubtitle1" />
            </span>
            {' '}
            <FormattedMessage id="landing.footerSubtitle2" />
          </p>
          <p className="footer__subtitle">
            <FormattedMessage id="landing.footerSubtitle3" />
          </p>
          <div className="footer__button">
            <Button/>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default Footer;
