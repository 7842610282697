import React from 'react';
import Wrapper from './wrapper';
import {FormattedMessage} from 'react-intl';

import './statements-list.scss';

const StatementsList = () => {
  return (
    <ul className="statementsList">
      <li className="statementsList__item bg-dark">
        <Wrapper>
          <h3 className="statementsList__header">
            <span>
              <FormattedMessage id="landing.headerOne" />
            </span>
          </h3>

          <p className="statementsList__content">
            <FormattedMessage id="landing.statementOne" />
            <br/>
            <br/>
            <span className="statementsList__main">
              <FormattedMessage id="landing.statementOneBold" />
            </span>
          </p>
          <p className="statementsList__notion">
            <FormattedMessage id="landing.statementOneNotion1" />
            {' '}
            <a href="https://edition.cnn.com/" target="_blank" rel="noopener noreferrer">CNN</a>
            ,
            {' '}
            <a href="https://www.bbc.com/" target="_blank" rel="noopener noreferrer">the BBC</a>
            ,
            {' '}
            <a href="https://www.theguardian.com/international" target="_blank" rel="noopener noreferrer">the Guardian</a>
            {' '}
            <FormattedMessage id="landing.and" />
            {' '}
            <a href="http://www.reuters.com/" target="_blank" rel="noopener noreferrer">Reuters</a>
          </p>
          <p className="statementsList__notion">
            <FormattedMessage id="landing.statementOneNotion2" />
          </p>
        </Wrapper>
      </li>
      <li className="statementsList__item">
        <Wrapper>
          <h3 className="statementsList__header">
            <span>
              <FormattedMessage id="landing.headerTwo" />
            </span>
          </h3>

          <p className="statementsList__content">
            <FormattedMessage id="landing.statementTwo1" />
            <br/>
            <br/>
            <FormattedMessage id="landing.statementTwo2" />
            {' '}
            <a href="https://www.airbnb.org/help-ukraine" target="_blank" rel="noopener noreferrer">
              <FormattedMessage id="landing.statementTwoLink" />
            </a>
          </p>
        </Wrapper>
      </li>
      <li className="statementsList__item bg-dark">
        <Wrapper>
          <h3 className="statementsList__header">
            <span>
              <FormattedMessage id="landing.headerTree" />
            </span>
          </h3>

          <p className="statementsList__content statementsList__content_big">
            <FormattedMessage id="landing.statementTreeNotion" />
          </p>
          <p className="statementsList__content">
            <FormattedMessage id="landing.statementTree" />
          </p>
        </Wrapper>
      </li>
    </ul>
  );
};

export default StatementsList;
