import React from 'react';
import {FormattedMessage} from 'react-intl'

import Wrapper from './wrapper';
import Button from './button';
import flag from '../images/icn_flag.svg';

import './header.scss';

const Header = () => {
  return (
    <Wrapper>
      <img
        className="flag-image"
        src={flag}
        width="75"
        height="75"
        alt="ukranian flag"
      />
      <h1 className="header__title">
        <span>
          <FormattedMessage id="landing.headerTitle" />
        </span>
      </h1>
      <h2 className="header__subtitle">
        <FormattedMessage id="landing.headerSubtitle" />
      </h2>
      <Button/>
    </Wrapper>
  );
};

export default Header;
