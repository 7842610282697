import React from 'react';
import Wrapper from './wrapper';
import {FormattedMessage} from 'react-intl';

import './steps.scss';

const Steps = () => {
  return (
    <Wrapper>
      <p className="steps__description">
        <FormattedMessage id="landing.stepsDescription" />
      </p>
      <ul className="steps__list">
        <li className="steps__item">
          <b>
            <FormattedMessage id="landing.stepsItemTitle1" />
          </b>
          {' '}
          <FormattedMessage id="landing.stepsItemText1" />
        </li>
        <li className="steps__item">
          <b>
            <FormattedMessage id="landing.stepsItemTitle2" />
          </b>
          {' '}
          <FormattedMessage id="landing.stepsItemText2" />
        </li>
        <li className="steps__item">
          <b>
            <FormattedMessage id="landing.stepsItemTitle3" />
          </b>
          {' '}
          <FormattedMessage id="landing.stepsItemText3" />
        </li>
        <li className="steps__item">
          <b>
            <FormattedMessage id="landing.stepsItemTitle4" />
          </b>
          {' '}
          <FormattedMessage id="landing.stepsItemText4" />
        </li>
        <li className="steps__item">
          <b>
            <FormattedMessage id="landing.stepsItemTitle5" />
          </b>
          {' '}
          <FormattedMessage id="landing.stepsItemText5" />
        </li>
      </ul>
      <p className="steps__footer">
        <FormattedMessage id="landing.stepsFooter" />
        {' '}
        <strong>#StandwithUkraine</strong>.
      </p>
    </Wrapper>
  );
};

export default Steps;
