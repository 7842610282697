import React from 'react';
import {FormattedMessage} from 'react-intl';
import Wrapper from './wrapper';

import photo from '../images/photo.png';
import photo2x from '../images/photo@2x.png';
import photo3x from '../images/photo@3x.png';
import photo_tablet from '../images/photo_tablet.png';
import photo_tablet2x from '../images/photo_tablet@2x.png';
import photo_tablet3x from '../images/photo_tablet@3x.png';
import photo_mobile from '../images/photo_mobile.png';
import photo_mobile2x from '../images/photo_mobile@2x.png';
import photo_mobile3x from '../images/photo_mobile@3x.png';
import './description.scss';

const Description = () => {
  return (
    <div className="bg-dark">
      <Wrapper>
        <p className="description__content">
          <span className="description__main">
            <FormattedMessage id="landing.mainBold" />
          </span>
          <br/>
          {' '}
          <FormattedMessage id="landing.main" />
        </p>
      </Wrapper>
      <picture>
        <source srcSet={`${photo_mobile3x} 3x, ${photo_mobile2x} 2x, ${photo_mobile}`} media="(max-width: 768px)" />
        <source srcSet={`${photo_tablet3x} 3x, ${photo_tablet2x} 2x, ${photo_tablet}`} media="(min-width: 1200px)" />
        <img srcSet={`${photo3x} 3x, ${photo2x} 2x, ${photo}`} alt="crowd hiding in underground" className="description__image" />
      </picture>
    </div>
  );
};

export default Description;
