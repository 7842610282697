import React from 'react';
import Helmet from 'react-helmet';

import Description from '../components/description';
import Header from '../components/header';
import StatementsList from '../components/statements-list';
import Steps from '../components/steps';
import Footer from '../components/footer';
import socialImg from '../images/social.jpeg';

import '../styles/main.css';

const Landing = () => {
  return (
    <div className="column">
      <Helmet>
        <title>
          Stand with Ukraine in its fight against Russian aggression
        </title>
        <meta name="description" content="Ukraine was attacked by Russian troops on Tuesday, February 24. We’ve gathered a list of things you can support Ukraine." />
        <meta property="og:title" content="Stand with Ukraine in its fight against Russian aggressionv" />
        <meta property="og:description" content="Ukraine was attacked by Russian troops on Tuesday, February 24. We’ve gathered a list of things you can support Ukraine." />
        <meta property="og:image" content={`${socialImg}`} />
        <meta property="og:url" content="" />
      </Helmet>

      <div className="top">
        <Header />

        <main>
          <Description />
          <StatementsList />
          <Steps />
        </main>
      </div>

      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default Landing;
