import React from 'react';
import {IntlProvider} from 'react-intl';

import en from '../translations/en.json';
import Landing from '../components/landing';

import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";

const Index = () => {
  return (
    <IntlProvider locale ={'en'} messages={en}>
      <Landing />
    </IntlProvider>
  );
};

export default Index;
